import { Injectable, effect, inject, untracked } from '@angular/core';
import { LocalStorageEnum } from '@enums/local-storage.enum';
import { PermissionEntity, PermissionOperation } from '@models/me.model';
import { AuthStateService } from '@state-management/auth-state';
import { initialState } from '@state-management/me-config-state/me-config-state.config';
import { MeConfigStateService } from '@state-management/me-config-state/me-config-state.service';
import { MeProfileStateService } from '@state-management/me-profile-state/me-profile-state.service';
import { SystemStateService } from '@state-management/system-state';
import { debouncedSignal } from '@utils/debounce-signal';
import { IsEqualObject } from '@utils/object-manipulation';
import { finalize, take, tap } from 'rxjs';
import { MePermissionsStateService } from '../state-management/me-permissions-state/me-permissions-state.service';
import { MeApiService } from './data-access/entities/me-api.service';

@Injectable({
  providedIn: 'root',
})
export class MeService {
  private readonly authStateService = inject(AuthStateService);
  private readonly systemStateService = inject(SystemStateService);
  private readonly meApiService = inject(MeApiService);
  private readonly meProfileStateService = inject(MeProfileStateService);
  private readonly meConfigStateService = inject(MeConfigStateService);
  private readonly mePermissionsStateService = inject(
    MePermissionsStateService,
  );
  private readonly meStateDebounced = debouncedSignal(
    this.meConfigStateService.state,
  );

  private isMeConfigFetched =
    this.systemStateService.getValue('isMeConfigFetched');

  constructor() {
    this.propagateMeConfigStateEffect();
    this.loadDataInLocalStorage();
  }

  initMeStates(): void {
    const isLogged = this.authStateService.isLogged();
    if (!isLogged) return;
    this.loadToStateMeProfileAndMeConfig();
    this.loadToStateMePermissions();
  }

  resetMeStates(): void {
    this.meProfileStateService.resetState();
    this.meConfigStateService.resetState();
    this.mePermissionsStateService.resetState();
    this.systemStateService.setValue('isMeConfigFetched', false);
  }

  private loadToStateMeProfileAndMeConfig(): void {
    this.meApiService
      .getMe()
      .pipe(
        take(1),
        tap(({ app_config: meConfig, ...meProfile }) => {
          this.meProfileStateService.setMultipleValues(meProfile);
          this.meConfigStateService.setMultipleValues(meConfig);
        }),
        finalize(() =>
          this.systemStateService.setValue('isMeConfigFetched', true),
        ),
      )
      .subscribe();
  }

  private loadToStateMePermissions(): void {
    this.meApiService
      .getMePermissions()
      .pipe(
        take(1),
        tap((permissions) => {
          permissions.forEach((permission) => {
            const [entity, operation] = permission.split('.') as [
              PermissionEntity,
              PermissionOperation,
            ];

            this.mePermissionsStateService.entity(entity).set(operation, true);
          });
        }),
      )
      .subscribe();
  }

  private propagateMeConfigStateEffect(): void {
    effect(() => {
      const isMeConfigFetched = this.isMeConfigFetched();
      const meConfigState = this.meStateDebounced();
      const isOffline = this.systemStateService.isOffline();

      if (IsEqualObject(meConfigState, initialState)) return;

      if (!isMeConfigFetched || isOffline) return;

      untracked(() => {
        this.meApiService
          .modifyMeAppConfig(meConfigState)
          .pipe(take(1))
          .subscribe();
      });
    });
  }

  private loadDataInLocalStorage(): void {
    effect(() => {
      this.setCorporateIDInLocalStorage();
      this.setAppConfigInLocalStorage();
      this.setRefreshInLocalStorage();
      this.setIsOfflineInLocalStorage();
      this.setLimitsInLocalStorage();
      this.setLastDownloadHistogramsParamsInLocalStorage();
    });
  }

  private setLastDownloadHistogramsParamsInLocalStorage(): void {
    const lastDownloadHistogramsParams = this.systemStateService.getValue(
      'lastDownloadHistogramsParams',
    )();

    localStorage.setItem(
      LocalStorageEnum.LastDownloadHistogramsParams,
      JSON.stringify(lastDownloadHistogramsParams),
    );
  }

  private setLimitsInLocalStorage(): void {
    const limits = this.meProfileStateService.getValue('limits')();

    localStorage.setItem(LocalStorageEnum.UserLimits, JSON.stringify(limits));
  }

  private setIsOfflineInLocalStorage(): void {
    const isOffline = this.systemStateService.getValue('isManualOffline')();

    localStorage.setItem(LocalStorageEnum.IsOffline, isOffline + '');
  }

  private setRefreshInLocalStorage(): void {
    const refresh = this.authStateService.getValue('refresh')();

    localStorage.setItem(LocalStorageEnum.Refresh, refresh + '');
  }

  private setCorporateIDInLocalStorage(): void {
    const corporateID = this.meProfileStateService.getValue('corporate_id')();

    localStorage.setItem(
      LocalStorageEnum.Corporate,
      JSON.stringify(corporateID),
    );
  }

  private setAppConfigInLocalStorage(): void {
    const appConfig = this.meConfigStateService.state();

    localStorage.setItem(LocalStorageEnum.AppConfig, JSON.stringify(appConfig));
  }
}
