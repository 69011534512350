import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthLogin, RefreshTokenPostResponse } from '@models/auth.model';
import { AuthApiService } from '@services/data-access/entities/auth-api.service';
import { AuthStateService } from '@state-management/auth-state';
import { parseJWT } from '@utils/jwt-manipulation';
import { Observable, take, tap } from 'rxjs';
import { TasksIndexedDbService } from './indexed-db/entities/tasks-indexed-db.service';
import { MeService } from './me.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly router = inject(Router);
  private readonly authApiService = inject(AuthApiService);
  private readonly authStateService = inject(AuthStateService);
  private readonly meService = inject(MeService);
  private readonly tasksIndexedDbService = inject(TasksIndexedDbService);

  login(data: AuthLogin): Observable<RefreshTokenPostResponse> {
    return this.authApiService.postObtainToken(data).pipe(
      tap(({ access, refresh }) => {
        this.authStateService.setMultipleValues({
          access,
          refresh,
        });
      }),
      tap(({ refresh }) => {
        this.setExpirationDateFromToken(refresh);
        this.meService.initMeStates();
      }),
    );
  }

  logout(): void {
    localStorage.clear();
    this.authStateService.resetValue('tokenExpiredAt');
    this.router.navigate(['authentication/login']);
    this.meService.resetMeStates();
    this.tasksIndexedDbService.deleteAllData().pipe(take(1)).subscribe();
  }

  refreshToken(refreshToken: string): Observable<RefreshTokenPostResponse> {
    return this.authApiService.postRefreshToken(refreshToken).pipe(
      tap(({ access, refresh }) => {
        this.authStateService.setMultipleValues({
          access,
          refresh,
        });
      }),
      tap(({ refresh }) => this.setExpirationDateFromToken(refresh)),
    );
  }

  setExpirationDateFromToken(token: string): void {
    const result = parseJWT(token);
    if (!result) return;

    this.authStateService.setValue('tokenExpiredAt', result.exp);
  }
}
